<template>
  <div class="d-flex data-entry flex-column flex-grow-1">
    <resize-observer @notify="setScrollbarHeight" />
    <perfect-scrollbar id="container-scroll" :style="{height: scrollbarHeight}">
      <div class="approval conference-card">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <ifac-loader
                v-if="conferenceLoading"
                :style="{height: `${conferenceLoaderHeight}px`}"
              />
              <ifac-conference-header
                v-else
                header-type="approval"
                :countries="countries"
                :conference="conference"
                :is-liaison="isCbLiaison"
                :is-conference-board="mx_isCbChair"
              />
            </div>
          </div>
        </div>
        <div class="card-body" v-if="displayCommentsForm">
          <ifac-loader
            v-if="approvalTreesLoading && !affiliate"
            :style="{height: approvalLoaderHeight}"
          />
          <div class="row" v-else>
            <div class="col">
              <!-- ANSWERS -->
              <ifac-approval-answers-form
                :read-only="readOnly"
                :conference="conference"
                :answers="approval.meta_answers"
                v-if="approvalIfForTcChair && approval"
                @change-answers="approval.meta_answers = $event"
              />
              <!-- COMMENTS FORM -->
              <ifac-approval-comments-form
                :approval="approval"
                :read-only="readOnly"
                :conference="conference"
                :affiliate="affiliate"
                :position-name="positionName"
                :position-label="positionLabel"
                @approval-status="approval.response = $event"
                @approval-comments="approval.notes = $event"
                @approval-comments-on-amendments="approval.notes_on_amendments = $event"
                @approval-comments-on-terminated="approval.notes_on_terminated = $event"
              />
            </div>
          </div>
          <div class="row submit-buttons">
            <div class="col text-right right-col p-0">
              <button
                type="submit"
                class="btn btn-secondary"
                :disabled="buttonIsDisabled || readOnly"
                @click="saveAndExit"
              >
                Save and exit
              </button>
              <button
                type="submit"
                class="btn btn-primary"
                :disabled="buttonIsDisabled || readOnly"
                @click="save"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <main-footer ref="footer"></main-footer>
    </perfect-scrollbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Approvals from '@/services/Api/Approvals';
import Affiliates from '@/services/Api/Affiliates';
import {
  IfacLoader,
  ifacUiMixin,
  ifacPositionsMixin,
  IfacConferenceHeader,
  IfacApprovalAnswersForm,
  IfacApprovalCommentsForm,
  ifacApprovalTreeMixin,
  ConferenceStatuses,
  IfacConferencesHelpers,
  IfacPositionHelpers,
} from '@ifac/ui';
import MainFooter from '@/views/layouts/MainFooter.vue';

export default {
  name: 'ApprovalsDetails',
  components: {
    IfacLoader,
    IfacConferenceHeader,
    IfacApprovalCommentsForm,
    IfacApprovalAnswersForm,
    MainFooter,
  },
  mixins: [
    ifacUiMixin,
    ifacApprovalTreeMixin,
    ifacPositionsMixin,
  ],
  props: {
    approvalTrees: {
      type: Array,
    },
    conference: {
      type: Object,
    },
    conferenceLoading: {
      type: Boolean,
      default: false,
    },
    approvalTreesLoading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    approvalTrees: {
      deep: true,
      handler(data) {
        this.setApprovalData(data);
        this.fetchAffiliate();
      },
    },
    approvalIfForTcChair: {
      handler(data) {
        this.setSpeechBubbleDisabled(data);
      },
    },
  },
  data() {
    return {
      approval: null,
      position: null,
      affiliate: null,
      scrollbarHeight: 0,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.countries.data,
    }),
    editableStatuses() {
      const { approved, released } = ConferenceStatuses;
      return [approved, released];
    },
    readOnly() {
      return !this.editableStatuses.find((s) => s.id === this.conference?.status);
    },
    currentPositions() {
      return this.position ? [this.position] : [];
    },
    approvalIfForTcChair() {
      return this.position?.entityType === 'technicalCommittee';
    },
    positionName() {
      if (this.isCbLiaison) {
        return IfacPositionHelpers.cbLiaison.name;
      }

      const position = this.mx_formattedPositions.find((p) => {
        const positionId = this.position?.id;
        if (p.role) return p.role.id === positionId;
        if (p.subcategory) return p.subcategory.id === positionId;
        return p.category.id === positionId;
      });

      return this.mx_getPositionNameWithoutTriennium(position);
    },
    displayCommentsForm() {
      if (this.isCbChair) {
        // Display only if the conference has finnacial support
        // and the CB Liaison has submitted his approval.
        return this.hasFinancialRequest
          && !!this.mx_cbLiaison?.approval?.submitted_at;
      }

      if (this.isCbLiaison) {
        // Display only if the conference has finnacial support.
        return this.hasFinancialRequest;
      }

      return true;
    },
    isCbChair() {
      return this.mx_cbChair?.approval?.id === this.mx_approvalId;
    },
    isCbLiaison() {
      return this.mx_cbLiaison?.approval?.id === this.mx_approvalId;
    },
    positionLabel() {
      return this.isCbLiaison
        ? IfacPositionHelpers.cbLiaison.label
        : (this.position?.entityType || this.position?.name);
    },
    conferenceId() {
      return this.$route.params.id;
    },
    answersHaveErrors() {
      const errors = this.approval?.meta_answers?.errors;

      return errors
        ? Object.keys(errors).length > 0
        : false;
    },
    buttonIsDisabled() {
      return !this.approval?.response;
    },
    conferenceLoaderHeight() {
      return 110;
    },
    approvalLoaderHeight() {
      return `calc(100vh - ${this.mx_upperZoneHeight + this.conferenceLoaderHeight}px)`;
    },
    hasFinancialRequest() {
      return IfacConferencesHelpers
        .hasFinancialRequest(this.conference);
    },
  },
  mounted() {
    this.setScrollbarHeight();
    this.setSpeechBubbleDisabled(this.approvalIfForTcChair);
  },
  methods: {
    ...mapMutations({
      setRequestError: 'formHelpers/setError',
      setIsCbNode: 'uiHelpers/setIsCbNode',
      setIsCbLiaisonNode: 'uiHelpers/setIsCbLiaisonNode',
      setIsTbChairNode: 'uiHelpers/setIsTbChairNode',
      setSpeechBubbleDisabled: 'uiHelpers/setSpeechBubbleDisabled',
    }),
    setScrollbarHeight() {
      this.scrollbarHeight = `calc(100vh - ${this.mx_upperZoneHeight}px)`;
    },
    async fetchAffiliate() {
      this.busy = true;
      const {
        data: { data },
      } = await Affiliates.show(this.approval?.affiliate_id);

      this.affiliate = data;
      this.busy = false;
    },
    goToConference() {
      this.$router.push({
        name: 'ConferencesOverview',
        params: {
          id: this.conferenceId,
        },
      });
    },
    sanitizeList(list) {
      return list.filter((o) => o);
    },
    preparePayload() {
      if (this.approval?.meta_answers) {
        const recommendation = {
          ...this.approval.meta_answers.data.organizationalLeadership.recommendation,
        };
        const mainList = this.sanitizeList(recommendation.main);

        /* eslint-disable max-len */
        this.approval.meta_answers.data.organizationalLeadership.recommendation.main = mainList;
      }

      return this.approval;
    },
    execErrorBlock(error, action) {
      if (error.response?.status === 422) {
        this.$snack.success({
          text: error.response.data.error.message,
        });
        this.setRequestError(error.response.data.error);
      } else {
        this.$snack.success({
          text: `There was an error ${action} the approval, please try again.`,
        });
      }
    },
    execSuccessBlock(data, action) {
      this.approval = data;
      this.setRequestError(null);
      this.$snack.success({
        text: `Your approval was ${action} successfully!`,
      });
    },
    async saveAndExit() {
      try {
        const response = await Approvals.update(this.preparePayload());
        const approvalData = response?.data?.data;

        if (response?.status === 200 && approvalData) {
          this.execSuccessBlock(approvalData, 'updated');
          this.goToConference();
        }
      } catch (error) {
        this.execErrorBlock(error, 'updating');
      }
    },
    async save() {
      try {
        const saveResponse = await Approvals.update(this.preparePayload());
        const saveData = saveResponse?.data?.data;

        if (saveResponse?.status === 200 && saveData) {
          this.approval = saveData;
          this.execSuccessBlock(saveData, 'updated');
          this.goToConference();
        }
      } catch (error) {
        this.execErrorBlock(error, 'updating');
      }
    },
    setInitialData() {
      const cbNodesList = [
        ...[this.mx_cbChair],
        ...[this.mx_cbLiaison],
      ];

      const nodesList = [
        ...cbNodesList,
        ...[this.mx_tbChair],
        ...this.mx_tbVCs,
        ...this.mx_cCs,
        ...this.mx_tCs,
      ];

      const data = nodesList.find((n) => n.approval?.id === this.mx_approvalId);

      this.approval = data?.approval;
      this.position = data?.position;

      this.setIsCbNode(
        !!cbNodesList.find((n) => n.approval?.id === this.mx_approvalId),
      );

      this.setIsCbLiaisonNode(
        !![this.mx_cbLiaison].find((n) => n.approval?.id === this.mx_approvalId),
      );

      this.setIsTbChairNode(
        (this.mx_tbChair?.approval?.id === this.mx_approvalId),
      );
    },
    setApprovalData() {
      this.mx_breakdownTreeNodes(this.approvalTrees);
      this.setInitialData();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '@/assets/styles/base/_variables.scss';
  @import '@/assets/styles/base/_mixins.scss';

  .data-entry {
    .submit-buttons {
      border-top: 0;

      @include media(mobile) {
        .right-col {
          .btn {
            width: 100%;
            margin-left: 0;

            &:last-of-type {
              margin-top: 1rem;
            }
          }
        }
      }
    }
  }
</style>
